import React, { Component } from "react";

import 'intersection-observer';
import { InView } from 'react-intersection-observer';

export default class PageBreak extends Component {
	handleInView(inView) {
		if (inView) {
			this.pagebreak.classList.remove("invisible");
		}
	}
	render() {
		let outside_classes = this.props.className ? " " + this.props.className : "";
		return <InView as="div" triggerOnce={true} rootMargin="0% 0% -20%" onChange={inView => this.handleInView(inView)} className={"page-break-container grid" + outside_classes}>
			<div className="page-break grid-m-6 grid-t-16 grid-d-16 default-animation invisible" ref={c => this.pagebreak = c}></div>
		</InView>;
	}
}